import Vue from 'vue'
import VueRouter from 'vue-router'

const routerPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error=> error)
}
Vue.use(VueRouter)
import Index from '../view/index.vue'  //首页
import List from '../view/list.vue'  //首页


const routes = [
	{
		path:'/',
		redirect:'/index'
	},
	{
		name:'index',
		path:'/index',
		component:Index,
		meta:{
			title:'乐佑门窗',
		}
	},
	{
		name:'list',
		path:'/list',
		component:List,
		meta:{
			title:'商品列表',
		}
	},
]

const router = new VueRouter({
  routes
})

export default router
